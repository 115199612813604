'use client';
import { PageTypes } from '@/shared/app';
import { $homePageStore } from 'app/app.model';
import { useUnit } from 'effector-react';

import Link from 'next/link';

export const ReturnHomeLink = () => {
    const mainPage = useUnit($homePageStore);
    return mainPage && <Link href={`/${mainPage?.slug === PageTypes.Home ? '' : mainPage}`}>Return To Main Page</Link>;
};
